<template>
    <div>
        <!-- CAROUSEL-->
      
    <b-row>

      <b-col class="back-home-body">
          <b-row>
            <b-col  class="real-text mt-5"  >
              <b style="font-size:xx-large;">REAL ESTATE TULUM</b>
            </b-col>  
          </b-row>
          <b-row align-h="center">

            <b-col  cols="11" sm="11" md="10" lg="10" xl="10">
              <b-row>
                <b-col cols="11" sm="6" md="4" lg="3" xl="4" class="mt-5 mb-5 " >
                  <div class="mb-3" style="cursor:pointer;">
                    
                      <b-img :src="require('../assets/amirap.jpg')" fluid @click="$router.push('/p101')" >></b-img>
                    
                  </div>  
                  <h4><b>Tulum starting: $162,400 USD</b></h4>
                  <h4><b>264 condos phase 1</b></h4>
                </b-col>
                
                <b-col cols="11" sm="6" md="4" lg="3" xl="4" class="mt-5 mb-5">
                   <div class="mb-3" style="cursor:pointer;">
                    
                      <b-img :src="require('../assets/atthap.jpg')" fluid @click="$router.push('/p102')"></b-img>
                    
                  </div> 
                  <h4><b>Holistika, Tulum 25 condos</b></h4>
                  <h4><b>starting at: $145,000 USD</b></h4> 
                </b-col>
                <b-col cols="11" sm="6" md="4" lg="3" xl="4" class="mt-5 mb-5">
                   <div class="mb-3" style="cursor:pointer;">
                    
                      <b-img :src="require('../assets/dejavup.jpg')" fluid @click="$router.push('/p103')"></b-img>
                    
                  </div>  
                  <h4><b>Aldea Zama. Tulum starting at</b></h4>
                  <h4><b>$289,640 USD 10 years financing</b></h4>
                </b-col>
                <b-col cols="11" sm="6" md="4" lg="3" xl="4" class="mt-5 mb-5">
                   <div class="mb-3" style="cursor:pointer;">
                    
                      <b-img :src="require('../assets/mestizap.jpg')" fluid @click="$router.push('/p104')" ></b-img>
                    
                  </div>  
                  <h4><b>Aldea Zama, Tulum</b></h4>
                  <h4><b>starting $228,462 USD</b></h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
      </b-col>
    </b-row>

    <b-row align="center">
      <b-col class="back-home-body">
        <b-row align-h="center">
          <b-col cols="10" sm="8" md="4" lg="3" xl="3">
            <b-row align-h="center">
              <b-col class="mt-4" cols="12"><b-img style="height:190px" :src="require('../assets/Susyfinalmente.png')" fluid ></b-img></b-col>
              <b-col class="mb-4 p-text mt-4" cols="12">
                <h6>Contact</h6>
                <h6>info@susimacdonald.com</h6>
                <h6>+52 984 167 4506</h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="11" sm="11" md="8" lg="5" xl="3" class="mt-4 mb-4 " >
            <b-row align-h="left" align="left">
              <div  style="background-color:transparent; padding:17px;">
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Name" type="text" validation="" v-model="form.name"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Email" type="email" validation="email" v-model="form.email"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Phone" type="text" validation="" v-model="form.phone"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Comment" type="textarea" validation="" v-model="form.comment"/></b-col>      
                <b-col cols="12"><b-button @click="enviarMail">Send</b-button></b-col> 
              </div>    

              <form  ref="form" >
                <input v-show="false" id="name" name="name" type="text"  v-model="form.name"> 
                <input v-show="false" id="email" name="email" type="text"  v-model="form.email"> 
                <input v-show="false" id="phone" name="phone" type="text"  v-model="form.phone"> 
                <input v-show="false" id="interest" name="interest" type="text"  v-model="form.interest"> 
                <input v-show="false" id="comment" name="comment" type="text"  v-model="form.comment"> 
              </form>
              
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    </div>
</template>
<script>
  import { mapState} from 'vuex'
  import emailjs from '@emailjs/browser';

  export default {
    components: {
    },
    computed: {
      ...mapState({
        
      }),
    },
    data() {
      return {
        form:{
          name:"",
          email:"",
          phone:"",
          interest:["Real State"],
          comment:""
        }
      }
    },
    methods: {
      enviarMail(){
        emailjs.sendForm('service_6nsrtfm', 'template_m0kyzjk', this.$refs.form, 'W8YrTfKVZ2WwQY5A-')
        .then((result) => {
            alert('Gracias por tu registro!', result.text);
            this.form.name="";
            this.form.email="";
            this.form.phone = "";
            this.form.interest=[];
            this.form.comment = "";
        }, (error) => {
            alert('Tu registro no se completo. Intenta más tarde', error.text);
        });
      }
    },
  }
</script>
<style scoped>
@import url('../assets/snow.min.css'); 

.back-home-body{
  background-image: url('../assets/texturasusy.jpg'); 
  background-size: auto;
}

</style>